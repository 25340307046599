

















































import AuthService from "@/services/AuthService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class PasswordRequest extends Vue {
  submitted = false;
  successful = false;
  isLoading = false;
  email = "";
  async requestPassword() {
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      this.isLoading = false;
      this.submitted = true;
      return;
    }
    this.isLoading = true;
    await AuthService.requestAccessCode(this.email);
    this.isLoading = false;
    this.successful = true;
  }
}
